/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// -----     Icons     ----- //
.i-no-repeat { background-repeat: no-repeat; }
.i-empty-services { @extend .i-no-repeat; background-image: url("assets/empty-services.svg") }
.i-profile-complete { @extend .i-no-repeat; background-image: url("assets/profile-complete.svg") }
.i-plan-created-successfully { @extend .i-no-repeat; background-image: url("assets/plan-created-successfully.svg") }
.i-smartphone { @extend .i-no-repeat; background-image: url("assets/i-smartphone.svg"); }

// -----     Pages     ----- //
.auth-page {
  height: 100vh;
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-primary);
  .auth-card {
    margin: 0;
    width: 100%;
    display: flex;
    padding: 36px 20px;
    align-items: center;
    flex-direction: column;

    .button-container { .clear { height: 25px; } }
  }
}
.enrolled-page {
  padding: 0 16px;
  background: var(--ion-color-light);
}
.button-container {
  width: 100%;
  &.fixed {
    position: fixed;
    bottom: 0;
    width: 90%;
    margin-bottom: 3%;
  }
}
// -----     Cards     ----- //
.card {
  background: var(--ion-color-light);
  margin: 0 0 8px;
  padding: 4px 4px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  .title {
    font-size: 16px;
    margin: 3% 0 1px 3%;
    color: var(--ion-color-medium);
  }
}
.card-white {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  &.primary {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }

  &.secondary {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &.no-shadow {
    box-shadow: none;
  }
}
// -----     Fade-in-animation     ----- //
.fade-in {
  animation-name: fade-in;
  animation-duration: 300ms;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
// -----     Buttons     ----- //
.button {
  --border-radius: 24px;
  height: 48px;
  margin-top: 3%;
  text-transform: none;
  width: 100%;
  --box-shadow: none;
  ion-spinner {
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }
  &.primary-action {
  }
  &.primary-action-light {
    --background: var(--ion-color-primary-tint);
  }
  &.secondary-action {
    --background: transparent;
    --border-color: var(--ion-color-primary);
    --border-style: solid;
    --border-width: 1px;
    --color: var(--ion-color-primary);
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    .icon {
      margin-right: 8px;
    }
  }
  &.clear {
    --background: transparent;
    --color: var(--ion-color-primary);
    --color-activated: var(--ion-color-primary);
    --color-focused: var(--ion-color-primary);
    --color-hover: var(--ion-color-primary);
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
  }
  &.small {
    margin: 0;
    width: auto;
    height: 32px;
    --padding-end: 0;
    --padding-start: 0;
  }
  &.small-secondary {
    margin: 0;
    height: 30px;
    --background: transparent;
    --border-color: var(--ion-color-medium-contrast);
    --border-style: solid;
    --border-width: 1px;
    --color: var(--ion-color-secondary);
    --color-activated: var(--ion-color-secondary);
    --color-focused: var(--ion-color-secondary);
    --color-hover: var(--ion-color-secondary);
    --padding-end: 0;
    --padding-start: 0;
  }
}
// -----     Content     ----- //
ion-content {
  --background: var(--ion-color-light);
}
// -----     Content     ----- //
ion-input.custom {
  --color: var(--ion-color-dark-tint);
  margin: 10px 0 5px 0;
}
// -----     Headers     ----- //
ion-toolbar {
  --border-style: none;
  --ion-toolbar-background: var(--ion-color-light);
}
// -----     Footers     ----- //
ion-footer {
  background: var(--ion-color-light);
}
// -----     Forms     ----- //
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .input-item {
    width: 49%;
    margin: 20px 0;
    --padding-end: 0;
    --padding-start: 0;
    --inner-padding-end: 0;
    &:has(.input[errorText]) {
      --border-style: none;
    }
    .phone-number-prefix {
      left: 0;
      margin-top: 28px;
      position: absolute;
      &.disabled {
        color: var(--ion-color-medium);
      }
    }
    .input {
      font-size: 17px;
      --inner-padding-end: 0;
      --placeholder-color: var(--ion-color-medium-tint);
      &.phone-number {
        --padding-start: 42px;
        @media (max-width: 768px) { --padding-start: 50px; }
      }
      &.registration-plate {
        text-transform: unset;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      margin: 10px 0 10px 4px;
    }
  }
  &.login, &.register, &.recovery-password {
    margin: 42px 0 16px;
  }
}
// -----     Styles-by-ids     ----- //
#version {
  bottom: 0;
  color: var(--ion-color-medium-tint);
  font-size: 10px;
  left: 0;
  margin: 4px;
  position: fixed;
}
#logo {
  display: block;
  max-width: 160px;
  margin: 3% 0;
}
// -----     Texts     ----- //
.title-page {
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.6px;
  text-align: left;
  font-style: normal;
  display: flex;
  align-items: center;
}
p { margin: 0; }
// -----     Modals     ----- //
.datetime-modal {
  --background: transparent;
  .ion-page {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.setting-modal {
  --border-radius: 10px;
  --max-height: 55%;
  --max-width: 80%;
  --width: 80%;
  --height: 50%;
}

.auto-height {
  --height: auto;
  --border-radius: 10px;
  --max-width: 80%;
  --width: 80%;
}

// -----     General     ----- //
.margin-top-25 {
  margin-top: 25px;
}
.margin-4 {
  margin: 4%;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}

@media only screen and (orientation: landscape) and (max-width: 1200px) and (max-height: 740px) {
  body {
    transform: rotate(90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    max-height: 8000px;
    overflow-x: hidden;
    position: absolute;
    left: 100%;
  }
}

// -----     Others     ----- //
.custom-chat-link {
  color: black;
}
